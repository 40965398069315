import { createRoot } from 'react-dom/client';

import { ValidationApp } from 'apps/kiosk';
import { setCurrentAppName } from 'utils/currentApp';
import { initDatadog } from 'utils/datadog';

import * as serviceWorker from './serviceWorker';

setCurrentAppName('validation');
initDatadog('validation');

const root = createRoot(document.getElementById('root')!);

root.render(<ValidationApp />);

document.getElementById('initial-loader')?.remove();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
